import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { dirty: Boolean, formSubmit: Boolean };
  static targets = ['modal', 'include', 'exclude', 'cancelButton'];

  initialize() {
    this.dirty = false;
    this.formSubmit = false;
    this.cancelClicked = false;
    this.forms = this.element.querySelectorAll('form');
    this.setupFormListeners();
    this.setupCancelButtons();

    this.handleTurboBeforeVisit = this.handleTurboBeforeVisit.bind(this);
    this.handleTurboSubmitStart = this.handleTurboSubmitStart.bind(this);

    document.addEventListener(
      'turbo:before-visit',
      this.handleTurboBeforeVisit
    );

    document.addEventListener(
      'turbo:submit-start',
      this.handleTurboSubmitStart
    );
  }

  setupFormListeners() {
    this.forms.forEach((form) => {
      this.markDirty = this.markDirty.bind(this);

      form.addEventListener('change', this.markDirty);
      form.addEventListener('input', this.markDirty);

      // Input has already changed when a validation error exists in the form
      if (form.querySelector('[error]')) {
        this.dirty = true;
      }
    });
  }

  markDirty(event) {
    const { target, currentTarget } = event;

    // If the target is explicitly included, mark as dirty
    if (
      this.includeTargets.includes(target) ||
      this.includeTargets.includes(currentTarget)
    ) {
      this.dirty = true;
      return;
    }

    const defaultExcludes = ['.rm-input-search', 'rmv-search'];
    // Check if the target is excluded
    if (
      this.excludeTargets.includes(target) ||
      this.excludeTargets.includes(currentTarget) ||
      defaultExcludes.some((exclude) => target.matches(exclude)) ||
      defaultExcludes.some((exclude) => currentTarget.matches(exclude))
    ) {
      return;
    }

    // If neither the target nor the current target is included or excluded, mark as dirty
    this.dirty = true;
  }

  setupCancelButtons() {
    this.cancelButtonTargets.forEach((button) => {
      button.addEventListener('click', () => {
        this.cancelClicked = true;
        // Reset after a short delay to handle future clicks
        setTimeout(() => {
          this.cancelClicked = false;
        }, 100);
      });
    });
  }

  handleTurboBeforeVisit(event) {
    if (this.dirty && !this.formSubmit && !this.cancelClicked) {
      event.preventDefault();
      this.modalTarget.dataset.action = event.detail.url;
      this.modalTarget.open = true;
      this.formSubmit = false;
    }
  }

  handleTurboSubmitStart() {
    // don't show modal on form submit
    this.formSubmit = true;
  }

  continue() {
    this.modalTarget.open = false;
    window.location.href = this.modalTarget.dataset.action;
  }

  cancel() {
    this.modalTarget.open = false;
  }

  disconnect() {
    document.removeEventListener(
      'turbo:before-visit',
      this.handleTurboBeforeVisit
    );
    document.removeEventListener(
      'turbo:submit-start',
      this.handleTurboSubmitStart
    );
  }
}
