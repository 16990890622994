import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  // DEV: REFACTOR 'toggleable' -> 'element' when fully converted to RMV
  static targets = ['toggleable', 'element'];
  static values = { attribute: String };

  /**
   * Toggles the visibility of each target element.
   * Iterates over the list of element targets and sets their hidden property
   * to the opposite of its current value.
   *
   * @function toggle
   */
  toggle() {
    this.elementTargets.forEach((element) => {
      element.hidden = !element.hidden;
    });
  }

  /**
   * Toggles the value of a specified attribute for target elements based on the event's target component.
   * You can toggle <input type="hidden" value="true/false" /> or <input checked="true/false" /> for example using this function.
   *
   * @param {Event} e - The event object triggered by the user interaction.
   * @property {string} [attributeValue='value'] - The attribute to be toggled. Defaults to 'value' if not specified.
   * @property {Array} elementTargets - The list of target elements to be toggled.
   */
  toggleValue(e) {
    // On default the attribute to be toggled is 'value', but you can use any attribute you want using data-toggle-attribute-value="..." on the element
    const attribute = this.attributeValue || 'value';
    const component = e.target;
    const componentName = component.nodeName.toLowerCase();

    // Ensure the event is only processed for the top-level component
    if (component !== e.currentTarget) return;

    // 1. Define the attributes to be toggled for each component
    // DEV: You can add new components here and their respective attributes to be toggled
    const componentAttributes = {
      'rmv-accordion': 'open',
      'rmv-select': 'open',
      'rmv-action-trigger': 'open',
      'rmv-datepicker': 'open',
      'rmv-modal': 'open',
      'rmv-radio': 'checked',
      'rmv-checkbox': 'checked',
      'rmv-folder': 'selected',
    };

    if (componentAttributes.hasOwnProperty(componentName)) {
      // 2. Retrieve the attribute to be toggled for the current component
      const componentAttribute = componentAttributes[componentName];

      // 3. Toggle the attribute value for each target element (element is a hidden input for example)
      this.elementTargets.forEach((element) => {
        element[attribute] = !component[componentAttribute];
      });
    }
  }

  // DEV LEGACY: Check if SHOW and HIDE methods are really needed when fully converted to RMV
  show() {
    const el = this.element.querySelector('brms-card[event-id="toggleable"]');
    if (el != null) {
      el.removeAttribute('hidden');
    }
    // As soon as ReMark Components support this, use the line below and remove the above lines
    // this.toggleableTarget.removeAttribute('hidden');
  }

  // DEV LEGACY: Check if SHOW and HIDE methods are really needed when fully converted to RMV
  hide() {
    const el = this.element.querySelector('brms-card[event-id="toggleable"]');
    if (el != null) {
      el.setAttribute('hidden', 'true');
    }
    // As soon as ReMark Components support this, use the line below and remove the above lines
    // this.toggleableTarget.setAttribute('hidden', 'true');
  }
}
