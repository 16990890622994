import { Controller } from '@hotwired/stimulus';
import turboFetch from 'lib/turbo_fetch';

export default class extends Controller {
  static targets = ['upload'];
  static values = {
    url: String,
    answerSpreadsheetId: String,
    elementIdsToDisable: Array,
  };

  upload(e) {
    let formData = new FormData();
    formData.append('file', e.target.files[0]);
    if (this.answerSpreadsheetIdValue != '') {
      formData.append('answer_spreadsheet_id', this.answerSpreadsheetIdValue);
    }
    this.#disable_elements();
    turboFetch(
      `${this.urlValue}`,
      'POST',
      formData,
      null,
      this.#enable_elements.bind(this)
    );
  }

  remove(e) {
    e.preventDefault(); // prevent downloading the file
    const id = document.querySelector('[data-id]').dataset.id;
    turboFetch(`${this.urlValue}/${id}`, 'DELETE');
  }

  #disable_elements() {
    this.elementIdsToDisableValue.forEach((id) => {
      const element = document.getElementById(id);
      element.disabled = true;
    });
  }

  #enable_elements() {
    this.elementIdsToDisableValue.forEach((id) => {
      const element = document.getElementById(id);
      element.disabled = false;
    });
  }
}
